<template>
    <div>
        <div v-if="loading" class="loader-container">
            <div class="loader-mask">
                <v-progress-circular 
                    color="#5A4FA2" 
                    indeterminate 
                    size="50">
                </v-progress-circular>
            </div>
        </div>
        <div v-else>    
            <div class="form-container">
                <v-row style="margin: 20px 0;">
                    <v-col cols=12 >
                        <p class="form-title_whitout_subtitle">Edad del titular más joven: {{ prescoringData.titulares[0].edad }} años </p>
                        <input
                            class="custom-range"                        
                            type="range"
                            min="18"
                            max="70"
                            step="1"
                            v-model="prescoringData.titulares[0].edad"
                            @input="updateAge"
                        />
                    </v-col>
                </v-row>

                <div class="important-msg"> SI TE INTERESA UNA CUOTA DE APROXIMADAMENTE
                        <span style="color: #12868D; display:block; ">{{ this.cuota_hipoteca | currency}}€ </span>
                    DÉJANOS ALGUNOS DATOS MÁS
                </div>

                <v-form ref="form">
                    <div style="margin: 40px 0">
                    <h4 class="holder_title">Titular 1</h4>
                    <v-row>
                        <v-col cols=12 sm="2" class="holder-column_age">
                            <p class="title-down">Edad </p>
                            <v-text-field 
                                v-model="prescoringData.titulares[0].edad"
                                outlined
                                dense
                                v-on:keyup="setFilled"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5" class="holder-column_job_type">
                            <p class="title-down"> Tipo de contrato </p>
                            <v-autocomplete
                                attach
                                no-data-text="Selecciona un tipo de contrato"
                                v-model="prescoringData.titulares[0].contrato"
                                :items="vars.tipo_contrato"
                                outlined
                                item-text="name"
                                item-value="code"
                                dense
                                :rules="[...ruleRequired]"
                                v-on:keyup="isFilled=true"
                            />
                        </v-col>
                        <v-col cols="12" sm="5" class="holder-column_incorporation_date">
                            <p class="form-title_whitout_subtitle"> Antigüedad contrato (meses)</p>
                            <v-text-field
                                v-model="prescoringData.titulares[0].antiguedad"
                                outlined  
                                :rules="[...ruleRequired,...ruleNumber,...ruleMax720]"                              
                                v-on:keyup="isFilled=true"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <div class="add-button_container" v-if="this.newHolder == false">
                        <button class="add-button_circle" @click="addNewHolder"> 
                            <div class="add-button_plus">+</div></button>
                        <span class="add-button_text">Añadir otro titular</span>
                    </div>

                    <div v-if="this.newHolder == true">
                        <h4 class="holder_title">Titular 2</h4>
                        <v-row>
                            <v-col cols=12 sm="2" class="holder-column_age">
                                <p class="title-down">Edad </p>
                                <v-text-field 
                                    v-model="prescoringData.titulares[1].edad"
                                    outlined
                                    dense
                                    v-on:keyup="setFilled"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5" class="holder-column_job_type">
                                <p class="title-down"> Tipo de contrato </p>
                                <v-autocomplete
                                    attach
                                    no-data-text="Selecciona un tipo de contrato"
                                    v-model="prescoringData.titulares[1].contrato"
                                    :items="vars.tipo_contrato"
                                    outlined
                                    item-text="name"
                                    item-value="code"
                                    dense
                                    v-on:keyup="isFilled=true"
                                />
                            </v-col>
                            <v-col cols="12" sm="5" class="holder-column_incorporation_date">
                                <p class="form-title_whitout_subtitle"> Antigüedad contrato (meses) </p>
                                <v-text-field
                                    v-model="prescoringData.titulares[1].antiguedad"
                                    outlined
                                    dense
                                    v-on:keyup="isFilled=true"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>

                </div>
                    <v-col cols="12" style="margin: 15px 0;">
                        <p class="form-title"> Ingresos de la unidad familiar </p>
                        <p class="form-subtitle">Indica todos los ingresos netos justificados en Bancos y/o Rentas, incluyendo pagas extras prorrateadas, alquileres y variables</p>
                        <v-currency-field
                            class="monthly"
                            v-model="prescoringData.total_ingresos"
                            placeholder="0"
                            outlined
                            append-icon="mdi-currency-eur"
                            :rules="[...ruleRequired,...ruleNumber]"
                            v-on:keyup="isFilled=true"
                        ></v-currency-field>
                    </v-col>

                    <v-col cols="12">
                        <p class="form-title">Cuotas mensuales de préstamos/hipoteca (si tiene) </p>
                        <p class="form-subtitle">Suma todos los préstamos o hipotecas que paguen todos los titulares, asi calcularemos el ratio de endeudamiento de la operación
                        </p>
                        <v-currency-field
                            class="monthly"
                            v-model="prescoringData.total_gastos"
                            outlined
                            append-icon="mdi-currency-eur"
                            :rules="[...ruleRequired,...ruleNumber]"
                            v-on:keyup="isFilled=true"
                        ></v-currency-field>
                    </v-col>

                    <v-col cols=12>
                        <p class="form-title">¿Tienes acceso al aval ICO?</p>
                        <div class="options-container">
                            <div v-for="item in vars.yes_no_field" :key="item.name" 
                                class="radio-button_container" 
                                @click="prescoringData.aval_ico=item.code"
                            >
                            <div class="circle">  
                                <div v-if="prescoringData.aval_ico==item.code" class="isChecked"></div>
                            </div>
                            <label>{{item.name}}</label>
                            </div>
                        </div>
                    </v-col>

                    <div style="margin-top: 10px;  border-radius: 8px; padding: 10px  0;">
                    <v-col cols=12>
                        <p class="form-title_whitout_subtitle">Nombre y Apellidos </p>
                        <v-text-field 
                            v-model="prescoringData.nombre_completo"
                            outlined
                            dense
                            :rules="[...ruleRequired]"
                            v-on:keyup="setFilled"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <p class="form-title_whitout_subtitle"> Teléfono </p>
                        <v-text-field    
                            v-model="prescoringData.telefono"
                            outlined
                            :rules="[...ruleRequired,...rulePhone]"
                            v-on:keyup="setFilled"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <p class="form-title_whitout_subtitle"> Email </p>
                        <v-text-field
                            v-model="prescoringData.email"
                            outlined
                            :rules="[...ruleRequired,...ruleEmail]"
                            v-on:keyup="setFilled"
                        ></v-text-field>       
                    </v-col>
                </div>
                <v-row>
                    <v-col cols="12" class="d-flex align-center">
                        <v-checkbox class="my-3" style="padding-left: 12px;" :rules="[v => !!v || 'Por favor, acepta los términos de uso y la política de privacidad']" >
                            <template v-slot:label>
                                <div style="font-size: 14px; color: #5A4FA2;">He leído y acepto los <a href="https://helloteca.com/terminos-y-condiciones" target="_blank" @click.stop>Términos de uso</a> y la <a href="https://helloteca.com/politica-privacidad" target="_blank" @click.stop>Política de privacidad</a></div>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
                </v-form>
            </div>

            <div class="actions_container" style="justify-content: center;">
                <div class="d-flex flex-column justify-center">
                    <span v-if="isFilledOperation==false" class="filled-message ml-4"> Faltan datos obligatorios</span>
                    <button class="button-secundary_element" @click="goToEnd">
                        Conoce el resultado del estudio
                    </button>
                </div>
            </div>  
        </div>  
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import rulesMixin from '../../mixins/rulesMixin';

    export default {
        name: 'MotivationView',
        mixins: [rulesMixin],
        data() {
            return {
                calculatedRange: 0,
                newHolder: false,
                cuota_hipoteca: 0
            }
        },
        mounted() {
            window.scrollTo(0, 0);
            this.updateAge();
        },
        computed: {
            ...mapState([
                'vars',
                'prescoringData',
                'isFilledOperation',
                'loading',
                'utmParams'
            ]),
        },
        methods: {
            ...mapActions([
                'sendPrescoringData'
            ]),
            ...mapMutations([
                'SET_IS_FILLED',
                'SET_PRESCORING_DATA',
                'SET_LOADING',
            ]),
            setFilled(){
                this.SET_IS_FILLED(true)
            },
            calculateRange() {
            // 75- edad del menor: si es menor o igual a 30: resultado. si se pasa 30 fijo.
                const edad = this.prescoringData.titulares[0].edad;
                const range = 75 - edad;
                this.calculatedRange = range <= 30 ? range : 30;
                return this.calculatedRange;                
            },
            updateAge() {
                this.calculateRange();
                const interes = 4.41;
                this.calculo_cuota(this.prescoringData.precio_compra, this.calculatedRange, interes);
            },

            // FORMULA PARA CALCULAR CUOTA HIPOTECA
            PMT(data) {
                const ir = data.ir;
                const np = data.np;
                const pv = data.pv;
                const fv = 0.00;
                const type = 0;
                var pmt, pvif;
                if (ir === 0) return -(pv + fv)/np;
                pvif = Math.pow(1 + ir, np);
                pmt = - ir * (pv * pvif + fv) / (pvif - 1);
                if (type === 1) pmt /= (1 + ir);
                return pmt;
            },
            calculoRapido(){
                // let pagos = 30*12; //360
                // let pInteres = (4.1/100)/12; //0.003
                let data = {"ir":0.003, "np": 360, "pv": this.prescoringData.precio_compra};
                let cuota = this.PMT(data);
                this.cuota_hipoteca = Math.round(cuota) * -1;
            },

            // Método para calcular la cuota
            calculo_cuota(valor, anyos, interes){
                let pagos = anyos*12;
                let pInteres = (interes/100)/12;
                let data = {"ir":pInteres, "np": pagos, "pv": valor};
                let cuota = this.PMT(data);
                this.cuota_hipoteca = Math.round(cuota) * -1;
            },
            addNewHolder(e){
                e.preventDefault();
                this.newHolder = true;
            },
            async goToEnd() {
                if (this.$refs.form.validate()) {
                    let params = {
                        titulares: this.prescoringData.titulares.map(titular => ({
                            edad: titular.edad,
                            contrato: titular.contrato,
                            antiguedad: titular.antiguedad
                        })),
                        total_ingresos: this.prescoringData.total_ingresos,
                        total_gastos: this.prescoringData.total_gastos,
                        aval_ico: this.prescoringData.aval_ico,
                        nombre_completo: this.prescoringData.nombre_completo,
                        telefono: this.prescoringData.telefono,
                        email: this.prescoringData.email,
                        utms: {
                            utm_source: this.utmParams.utm_source || "",
                            utm_medium: this.utmParams.utm_medium || "",
                            utm_campaign: this.utmParams.utm_campaign || "",
                            utm_term: this.utmParams.utm_term || "",
                            utm_content: this.utmParams.utm_content || ""
                        }                    
                    };
                    this.SET_PRESCORING_DATA(params);
                    this.SET_LOADING(true);  
                    try {
                        await this.sendPrescoringData(this.prescoringData);
                    } finally {
                        this.SET_LOADING(false);
                    }
                } else {
                    this.SET_IS_FILLED(false);
                }
            },        
        },
        watch: {
            'prescoringData.titulares': {
                handler() {
                    this.updateAge();
                },
                deep: true
            },
            loading(newVal) {
                if (newVal) window.scrollTo(0, 0);            
            }
        }
    }
</script>

<style>
/* CUSTOMATIZA SLIDE DE EDAD */
    .custom-range {
        appearance:none;
        -webkit-appearance: none;
        width: 100%;
        height: 8px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        transition: opacity 0.2s;
    }
    .custom-range:hover {
        opacity: 1;
    }
    .custom-range::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        background: #5A4FA2;
        cursor: pointer;
        border-radius: 50%;
        }
    .custom-range::-moz-range-thumb {
        width: 25px;
        height: 25px;
        background: #5A4FA2;
        cursor: pointer;
        border-radius: 50%;
    }    
    
    .important-msg{
        border-radius: 8px;
        padding: 8px;
        margin: 8px 0;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        /* background-color:#12868D;
        color: white;
        border: 2px solid; */
        color: #5A4FA2;
        border: 2px solid #5A4FA2;
    }
    @media (min-width:750px){
        .important-msg{
            padding: 12px;
            margin: 12px;
            margin-bottom: 20px;
            font-size: 26px;
        }
    }
    .holder_title{
        font-size: 20px;
        font-weight: 500px;
        margin: 26px 0 12px;
    }
    .title-down{
        color:#5A4FA2;
        font-weight: 550;
        margin-bottom:33px !important;
    }    
    .holder-column_age{
        padding: 12px 6px 12px 24px;
    }
    .holder-column_job_type{
        padding: 12px 6px;
    }

    .holder-column_incorporation_date{
        padding: 12px 24px 12px 6px;
    }

    @media (max-width: 600px){
        .holder-column_age{
            padding: 12px 24px;
        }
        .holder-column_job_type{
            padding: 12px 24px;
        }
        .holder-column_incorporation_date{
            padding: 12px 24px;
        }
        .title-down{
            color:#5A4FA2;
            font-weight: 550;
            margin-bottom:8px !important;
        }
    }

/* BOTON DE AÑADIR OTRO TITULAR */
    .add-button_container{
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .add-button_circle{
        width: 35px;
        height: 35px;
        border: 1px solid #5A4FA2;
        border-radius: 100%;
        margin-right: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .add-button_plus{
        color:#5A4FA2;
        font-size: 30px;
        font-weight: 100;
    }
    .add-button_text{
        color:#5A4FA2;
        font-size: 14px;
    }
    @media (min-width: 600px){
        .add-button_circle{
            width: 45px;
            height: 45px;
            margin-right: 8px;
        }
        .add-button_plus{
            font-size: 35px;
        }
        .add-button_text{
            font-size: 16px;
        }
    }
    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }
    .isChecked{
        background-color: #5A4FA2;
    }
</style>